<div class="dialog__height" *ngIf="confirmDialogType === 0">
  <div mat-dialog-title>
    <div class="dialog__header no_border-bottom">
      <h2 id="txt_confirmationDialogTitle" class="typo-title">
        {{ title }}
      </h2>
      <button mat-icon-button id="btn_confirmationDialogClose" (click)="onDismiss()"
        *ngIf="!dialogRef.disableClose && showCloseIconOnTop">
        <icon name="close"></icon>
      </button>
    </div>
  </div>
  <div mat-dialog-content>
    <p id="txt_confirmationDialogMessage" class="typo-body1">
      {{ message }}
    </p>
  </div>
  <div mat-dialog-actions class="justify-content-end dialog__footer no_border-top">
    <button mat-button id="btn_confirmationDialogCancel" type="button" (click)="onDismiss()"
      *ngIf="!dialogRef.disableClose">
      {{ btnCancelText }}
    </button>
    <button mat-flat-button id="btn_confirmationDialogConfirm" type="button" color="primary" (click)="onConfirm()">
      {{ btnOkText }}
    </button>
  </div>
</div>
<div class="dialog__height" *ngIf="confirmDialogType === 1">
  <div mat-dialog-content class="text-center">
    <icon name="warning" svgFill="var(--m3-sys-error)" width="90" height="90">
    </icon>
    <p class="typo-title mt-3">{{ message }}</p>
  </div>
  <div mat-dialog-actions class="justify-content-end">
    <button id="btn_confirmationDialogCancel" mat-button type="button" class="text-uppercase" (click)="onDismiss()">
      {{ btnCancelText }}
    </button>
    <button id="btn_confirmationDialogConfirm" mat-button type="button" color="warn" class="text-uppercase"
      (click)="onConfirm()">
      {{ btnOkText }}
    </button>
  </div>
</div>
<div class="dialog__height" *ngIf="confirmDialogType === 2">
  <div mat-dialog-title>
    <div class="d-flex justify-content-end">
      <button id="btn_confirmationDialogClose" mat-icon-button (click)="onDismiss()">
        <icon name="close"></icon>
      </button>
    </div>
  </div>
  <div mat-dialog-content class="text-center">
    <icon name="warning" svgFill="var(--m3-sys-error)" width="90" height="90"> </icon>
    <h2 class="mt-3">{{ title }}</h2>
    <p>{{ message }}</p>
  </div>
  <div mat-dialog-actions class="justify-content-end">
    <button id="btn_confirmationDialogConfirm" mat-flat-button type="button" color="primary" class="text-uppercase"
      (click)="onDismiss()">
      {{ btnOkText }}
    </button>
  </div>
</div>