export class CustomerV3 {
    id?: string;
    localId?: string;
    name!: string;
    mobile?: string;
    phone?: string;
    email?: string;
    customerType: CustomerType = CustomerType.Consumer;
    nationalIdOrPassportNo?: string;
    taxRegistrationId?: string;
    customerAddresses: CustomerAddress[] = [];
    createdDate?: Date;
    mobileParts: string[] = [];

    public static clone(customer?: CustomerV3): CustomerV3 | undefined {
        if (!customer) return undefined;
        const newCustomer = Object.assign(new CustomerV3(), customer);
        newCustomer.customerAddresses = [];
        if (customer.customerAddresses?.length) {
            for (let index = 0; index < customer.customerAddresses.length; index++) {
                newCustomer.customerAddresses.push(CustomerAddress.clone(customer.customerAddresses[index]));
            }
        }
        return newCustomer;
    }

    public setDefaultAddress(index: number) {
        const defaultAddress = this.customerAddresses.find(itm => itm.isDefault);
        if (defaultAddress) {
            defaultAddress.isDefault = false;
        }

        this.customerAddresses[index].isDefault = true;
    }

    public addAddress(address: CustomerAddress) {
        if (!this.customerAddresses.length) address.isDefault = true;
        this.customerAddresses.unshift(address);
    }

    public removeAddress(index: number) {
        const removedAddress = this.customerAddresses[index];
        this.customerAddresses.splice(index, 1);

        // If the default address removed, set another address as default
        if (removedAddress.isDefault && this.customerAddresses.length) {
            this.setDefaultAddress(0);
        }
    }
}

export enum CustomerType {
    Consumer = 1,
    Business = 2
}

export class CustomerAddress {
    id?: string;
    country!: Country;
    state!: State;
    city!: City;
    district?: District;
    street!: string;
    phone?: string;
    buildingNumber!: string; 
    nearestLandmark?: string;
    postalCode?: string;
    addressType!: AddressType;
    isDefault?: boolean;

    public get description(): string {
        return `${this.buildingNumber}, ${this.street}, ${this.city?.name}, ${this.state?.name}, ${this.country?.name}.`;
    }

    public static clone(customerAddress: CustomerAddress): CustomerAddress {
        return Object.assign(new CustomerAddress(), customerAddress);
    }
}

export interface Country {
    id: number;
    name: string;
    iso2: string;
    iso3: string;
}

export interface State {
    id: number;
    name: string;
    countryId?: number;
    countryCode?: string;
}

export interface City {
    id: number;
    name: string;
    stateId?: number;
    stateCode?: string;
    countryId?: number;
    countryCode?: string;
}

export interface District {
    id: number;
    name: string;
    cityId: number;
}

export enum AddressType {
    Home = 1,
    Work = 2
}

export class MinifiedCustomerV3 {
    id?: string;
    localId?: string;
    name!: string;
    mobile?: string;
    email?: string;

    constructor(customer?: CustomerV3) {
        if (customer) {
            this.id = customer.id && !customer.localId ? customer.id : undefined;
            this.localId = customer.localId ?? undefined;
            this.name = customer.name;
            this.mobile = customer.mobile;
            this.email = customer.email;
        }
    }
}

